import { Member } from "../../actionTypes";

const initialState = {
  membersList: {},
};

const MemberReducer = (state = initialState, action) => {
  switch (action.type) {
   
    //CHART DASHBOARD
    case Member.MEMBER_LIST:  
      const isData = action.data ? { membersList: { ...action.data } } : null;
      return {
        ...state,
        ...isData,
        isLoading: action.isLoading,
      };
    case Member.MEMBER_DETAILS:  
      const memberDetails = action.data ? { memberDetails: { ...action.data } } : null;
      return {
        ...state,
        ...memberDetails,
        isLoading: action.isLoading,
      };
    case Member.MEMBER_INIT_CHAT:  
      const initChat = action.data ? { initChat: { ...action.data } } : null;
      return {
        ...state,
        ...initChat,
        isLoading: action.isLoading,
      };
    case Member.MEMBER_CHAT_DETAILS:  
      const chatDetails = action.data ? { chatDetails: { ...action.data } } : null;
      return {
        ...state,
        ...chatDetails,
        isLoading: action.isLoading,
      };
    // case Member.MEMBER_CREATE_CHAT:  
    //   const newChat = action.data ? { newChat: { ...action.data } } : null;
    //   return {
    //     ...state,
    //     ...newChat,
    //     isLoading: action.isLoading,
    //   };
    case Member.MEMBER_UPDATE_STATUS:  
      const memberUpdate = action.data ? { memberUpdate: { ...action.data } } : null;
      return {
        ...state,
        ...memberUpdate,
        isLoading: action.isLoading,
      };
    case Member.CITY_LIST:  
      const cityData = action.data ? { cityList: { ...action.data } } : null;
      return {
        ...state,
        ...cityData,
        isLoading: action.isLoading,
      };
    case Member.STATE_LIST:  
      const stateData = action.data ? { stateList: { ...action.data } } : null;
      return {
        ...state,
        ...stateData,
        isLoading: action.isLoading,
      };
    case Member.COURT_LIST: 
      const courtData = action.data ? { courtList: { ...action.data } } : null;
      return {
        ...state,
        ...courtData,
        isLoading: action.isLoading,
      };
    case Member.ADD_MEMBER:
        const memberDetilas = action.data ? { memberDetilas: { ...action.data } } : null;
        return {
          ...state,
          ...memberDetilas,
          isLoading: action.isLoading,
        };
    default:
      return state;
  }
};
export default MemberReducer;
