const userEndpoints = {
  login: "/user/AttorneyLogin",
  logout: "/userLogout",
  listUser: "/user/getUser/",
  chartList: "/tikkit/tikkitListing",
  listTikkits : "/tikkit/tikkitListing",
  getTikkit : "/tikkit/getTikkit/",
  updateTikkkitStatus : "/tikkit/tikkitVerification",
  listMembers : "/user/list",
  updateMemeberStatus : "/user/changeUserStatus",
  listCities : "/city/list",
  listStates : "/states/list",
  listCourtes : "/courtdistrict/list",
  forgotPassword : "/user/forgotPassword",
  resetPassword : "/user/resetPassword",
  changePassword : "/user/changePassword",
  getChatList : "/chat/list",
  createChat : "/chat/create",
  initChat : "/chat/initiateChat",
  add : "/user/add",
  listSupport : "/support/list",
  listPayment : "/stripe/list",
  // updateTikkkitStatus : "/tikkit/tikkitVerification",
};

export { userEndpoints };
