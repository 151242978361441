
// const apiUrl = "http://tikkit.local.com/api/";
// const fileUrl = "http://tikkit.local.com/api/upload/getfiles/";
const apiUrl = "https://tikkit.oneteamus.com/api/";
const fileUrl = "https://tikkit.oneteamus.com/api/upload/getfiles/";

// const apiUrl = "https://app.mytikkit.com:3020/api/";
// const fileUrl = "https://app.mytikkit.com:3020/api/upload/getfiles/";
export const API_ROUTE = `${apiUrl}`;
export const FILE_ROUTE = `${fileUrl}`;
