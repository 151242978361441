import React from "react";

const Login = React.lazy(() => import("./Components/pages/Auth"));
const Tikkits = React.lazy(() => import("./Components/pages/Tikkit"));
const TikkitDetail = React.lazy(() => import("./Components/pages/Tikkit/TikkitDetail"));
const TicketImage = React.lazy(() => import("./Components/pages/Tikkit/TicketImage"));
const Members = React.lazy(() => import("./Components/pages/Members"));
const MemberDetails = React.lazy(() => import("./Components/pages/Members/Details"));
const ForgotPassword = React.lazy(() => import("./Components/pages/ForgotPassword"));
const ForgotPasswordSuccess = React.lazy(() => import("./Components/pages/ForgotPassword/Sucess"));
const ResetPassword = React.lazy(() => import("./Components/pages/ForgotPassword/Reset"));
const Support = React.lazy(() => import("./Components/pages/Support"));
const Payment = React.lazy(() => import("./Components/pages/Payment"));
const ChangePassword = React.lazy(() => import("./Components/pages/ChangePassword"));

export const PrivateRouteArray = [
  { path: "/tikkit", component: Tikkits, exact: true },
  { path: "/tikkit/detail/:uid", component: TikkitDetail, exact: true },
  { path: "/tikkit/detail/:uid/:imageId", component: TicketImage, exact: true },
  { path: "/members/:userType", component: Members, exact: true },
  { path: "/members/:userType/:uid", component: MemberDetails, exact: true },
  { path: "/support", component: Support, exact: true },
  { path: "/payment", component: Payment, exact: true },
  { path: "/changepassword", component: ChangePassword, exact: true },
];

export const PublicRouteArray = [
  // { path: "/apploading", component: Dashboard, exact: true },
  { path: "/", component: Login, exact: true },
  { path: "/forgotpassword", component: ForgotPassword, exact: true },
  { path: "/forgotpassword/success", component: ForgotPasswordSuccess, exact: true },
  { path: "/ResetPassword/:token/:role", component: ResetPassword, exact: true },
  // { path: "/reset-password", component: Login },
];
