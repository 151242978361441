const Colors = {
 /*  primary: "#1285EF", */
  primary: "#005597",
  active: "#4CAF50",
  secondary: "#C6E6FF",
  default: "#A2A2A2",
  error: "#F4586C",
  warning: "#F9A000",
  info: "#22C0FA",
  black: "#000",
  white: "#fff",
  green: "#62C36D",
  grey: "#DDDDDD",
  // appBodyColor: "#f0f2f5",
  appBodyColor: "#F0F7FF",
  sentChipColor: "#62C36D",
  darkblue: "#323C58",
  tabColor: "#005597",
};

export default Colors;
