import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, path, isAuthenticated, userId, ...rest }) => {
  const render = (props) => <Component {...props} {...rest} />;

  return isAuthenticated ? <Route path={path} render={render} {...rest} /> : <Redirect to="/" />;
};

export default PrivateRoute;
