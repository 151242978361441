import { Auth } from "../../actionTypes";
import loginData from "../../../Config/util";
import gateWay, { authenticator } from "../../../Config/authorizationService";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { setSnackBarNotifications } from "../Global";

export const login = (param) => async (dispatch) => {
  let snackbarOptions = { open: true };
  try {    
    const res = await authenticator.post(userEndpoints.login, param);
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.status) {
        loginData(userDetails.data);
        await dispatch({ type: Auth.LOGIN_SUCCESS, loginAction: userDetails.data });
        snackbarOptions = { ...snackbarOptions, variant: "success", message: userDetails.message };
        await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
        return true;
      } else {
        await dispatch({ type: Auth.LOGIN_FAILURE, error: userDetails.data.findUser  });
        snackbarOptions = { ...snackbarOptions, variant: "error", message: userDetails.message };
      }
    } else {
      await dispatch({ type: Auth.LOGIN_FAILURE, error: res.data });
      snackbarOptions = { ...snackbarOptions, variant: "error", message: res.message };
    }
    await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    return false;
  } catch (e) {
      await dispatch({ type: Auth.LOGIN_FAILURE, error: e.response && e.response.data });
      snackbarOptions = { ...snackbarOptions, variant: "error", message: e.response.data.message };
      await dispatch(setSnackBarNotifications({ ...snackbarOptions }));
  }
};

export const logout = (param) => async (dispatch) => {
  await dispatch({ type: Auth.LOGIN_SUCCESS, loginAction: { success: false } });
};

export const getUser = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.listUser+params.ID);
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({ type: Auth.LIST_USER_SUCCESS, data: userDetails.data });
      } else {
        await dispatch({ type: Auth.LIST_USER_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.LIST_USER_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({ type: Auth.LIST_USER_FAILURE, error: e.response && e.response.data });
  }
};
