export const global = {
  SET_ROUTE: "SET_ROUTE",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
};

export const TEST = {
  SET_TEST: "SET_TEST",
};

export const Auth = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LIST_USER_SUCCESS: "LIST_USER_SUCCESS",
  LIST_USER_FAILURE: "LIST_USER_FAILURE",
};


export const Tikkit = {
  TIKKIT_LIST: "TIKKIT_LIST",
  TIKKIT_UPDATE_STATUS: "TIKKIT_UPDATE_STATUS",
  TIKKIT_DETAILS: "TIKKIT_DETAILS",
};

export const Member = {
  MEMBER_LIST: "MEMBER_LIST",
  MEMBER_DETAILS: "MEMBER_DETAILS",
  MEMBER_UPDATE_STATUS: "MEMBER_UPDATE_STATUS",
  CITY_LIST: "CITY_LIST",
  STATE_LIST: "STATE_LIST",
  COURT_LIST: "COURT_LIST",
  ADD_MEMBER: "ADD_MEMBER",
  ADD_MEMBER_ERROR: "ADD_MEMBER",
  MEMBER_CHAT_DETAILS: "MEMBER_CHAT_DETAILS",
  MEMBER_CREATE_CHAT: "MEMBER_CREATE_CHAT",
  MEMBER_INIT_CHAT: "MEMBER_INIT_CHAT",
};

export const Support = {
  SUPPORT_LIST: "SUPPORT_LIST",
};

export const Payment = {
  PAYMENT_LIST: "PAYMENT_LIST",
};

export const Forgotpassword = {
  FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
  FORGOTPASSWORD_FAILURE: "FORGOTPASSWORD_FAILURE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGEPASSWORD_FAILURE: "CHANGEPASSWORD_FAILURE",
};



