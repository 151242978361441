const loginData = (userDetails) => { console.log();
  localStorage.setItem("token", userDetails.token);
  localStorage.setItem("userType", userDetails.findUser.role);
  localStorage.setItem("userId", userDetails.findUser._id);
  localStorage.setItem("profileVerified", userDetails.findUser.profileVerified);
};

export default loginData;

export const auth_user = () => {
  const user = {
    token: localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userId: localStorage.getItem("userId"),
    profileVerified: localStorage.getItem("profileVerified"),
  };
  return user;
};

export const getStatusLabel = (status) => {
  if (status) {
    switch (status) {
      case 'Pending':
        return "Ticket Submitted";
      case 'Reject':
        return "Rejected";
      case 'Accept':
        return "Attorney Assigned";
      case 'Submitted':
        return "Appearance Submitted";
      case 'Scheduled':
        return "Court Date Scheduled";
      case 'Process':
        return "In-Process";
      case 'Appearance':
        return "Court Appearance";
      case 'Closed':
        return "Ticket Resolved";
      default:
        return status;
    }
  } 
};

export const isLogin = () => {
  if (localStorage.getItem("token")) return true;
  return false;
};

export const logout = () => localStorage.clear();
