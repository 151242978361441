import { Payment } from "../../actionTypes";

const initialState = {
    paymentList: {},
};

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Payment.PAYMENT_LIST:  
            const isData = action.data ? { paymentList: { ...action.data } } : null;
            return {
                ...state,
                ...isData,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};
export default PaymentReducer;
