import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Header from "../Header";
import SideBar from "../SideBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    height: "calc( 100vh - 80px)",
    backgroundColor: theme.palette.background.default,
    // backgroundColor: "#fff",
    // padding: theme.spacing(3),
    marginTop: 80,
    overflow: "auto",
    width: "100%",
    padding: 0,
  },
}));

const AppLayout = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <Grid className={classes.root}>
      <Header {...{ open, setOpen }} />
      {props?.noSideBar ? null : <SideBar {...{ open, setOpen }} />}
      <Grid className={classes.content}>{props.children}</Grid>
    </Grid>
  );
};

export default AppLayout;
