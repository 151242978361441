import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
// import { headerData } from "./helper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./header.css";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setRoute } from "../../../store/actions/Global";
import SimpleListMenu from "../Menu";
import { getUser, logout } from "../../../store/actions/Auth";
import {  ReactComponent as TikkitLogo } from '../../../Assets/Images/logo.svg';
import NoProfileImage from '../../../Assets/Images/noprofile.png';
import { FILE_ROUTE } from "../../../Constants/gateway";
import DialogNotification from "../Modal";

// import AutoCompleteBox from "../Autocomplete";
// import SideDrawer from "../SideDrawer";

const useStyles = makeStyles(() => ({
  appBar: {
    width: "100%",
    zIndex: 10,
    background:"#F2F5F7",
    borderBottom: "1px solid #9EBCD3",
  },
  // selected: {
  //   backgroundColor: `${Colors.White} !important`,
  //   color: Colors.white,
  // },
  // buttonHover: {
  //   backgroundColor: Colors.White,
  // },
  listRoot: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  regular: {
    height: 80,
  },
  logo: {
    height: "51px",
    width: "122px",
    marginLeft: "2%",
  }
}));
// const modalWrapper = {
//   position: "absolute",
//   width: "100%",
//   height: "100%",
//   background: "#fff",
//   left: 0,
//   bottom: 0,
//   overflow: "auto",
//   zIndex: 1,
//   opacity: 1,
// };

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);
  const [logoutStatusModal, setLogoutStatusModal] = React.useState(false);
  const [menuItem, setMenuItem] = React.useState("");

  const { pathname } = location;
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);

  // const handleRouteChange = (route) => {
  //   props.setRoute(route);
  //   history.push(route);
  // };

  const classes = useStyles();
  const matches = useMediaQuery("(max-width:767px)");
  const { setOpen } = props;

  const onRenderComponent = () => {
    return (
      <Grid style={{ padding: 20, alignItems: "left" }}>
        <Typography variant="h6" 
          style={{ color: "#005597", fontSize: "16px", fontFamily: "Poppins", fontWeight: 600, width: "100%"}} >
            Logout
        </Typography>
        <Typography variant="body2"  style={{ color: "#323C58", fontFamily: "Poppins", fontSize: "16px", width: "100%"}} >
          Are you sure you want to logout?
        </Typography>
      </Grid>
    );
  };

  const handleDialogWindowClose = () => {
    setLogoutStatusModal(false);
    setMenuItem("");
  };

  const onDialogActionsOnClick = async () => {
    setLogoutStatusModal(false);
    setMenuItem("");
    if (menuItem === "logout") {
      props.logout();
      history.push("/");
      localStorage.clear();
    }
  };

  const handleRoute = async () => {
    history.push("/tikkit");
  };

  useEffect(() => {
    if (!matches && sideDrawerOpen) {
      setSideDrawerOpen(false);
    }
  }, [matches, sideDrawerOpen]);

  const componentDidMount = async () => {
    let userId = localStorage.getItem("userId");
    await props.getUser({ ID: userId });
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  const handleMenuItemClick = (options) => {
    setMenuItem(options);
    setLogoutStatusModal(true);
  };

  return (
    <>
      <CssBaseline />
      <AppBar color="inherit" position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.regular}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            <TikkitLogo  className={classes.logo}   onClick={handleRoute} />
            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {matches ? (
                <IconButton style={{ margin: "0px 5px" }} onClick={() => setOpen(true)} key="1">
                  <MenuIcon />
                </IconButton>
              ) : null}
              <Grid>
                <IconButton onClick={(event) => setAnchorEl(event && event.target && event.currentTarget)} key="2">
                
                  { (props.listUser?.data?.profilePhoto !== "" || props.listUser?.data?.profilePhoto === undefined)? <img  src={NoProfileImage} title={props.listUser?.data?.firstName + " " + props.listUser?.data?.lastName} /> :  <Avatar title={props.listUser?.data?.firstName + " " + props.listUser?.data?.lastName} 
                    src={FILE_ROUTE+props.listUser?.data?.profilePhoto} />}
                </IconButton>
                {anchorEl ? (
                  <Grid>

                    <SimpleListMenu anchorEl={anchorEl} options={[{ id: "changepassword", title: "Change Password"}, { id: "logout", title: "Logout" }]} handleMenuItemClick={(options) => handleMenuItemClick(options)} onClose={() => setAnchorEl(null)} />
                  
                  </Grid>
                ) : null}
              </Grid>

           {/* {matches ? (
                <IconButton style={{ margin: "0px 5px" }} onClick={() => setSideDrawerOpen(!sideDrawerOpen)}>
                  {!sideDrawerOpen ? <MenuIcon /> : <CloseIcon />}
                </IconButton>
              ) : null} */}
              {/* {sideDrawerOpen &&
                ReactDOM.createPortal(
                  <div style={modalWrapper}>
                    <List classes={{ root: classes.listRoot }}>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/members" ? true : false}
                        button
                        onClick={() => handleRouteChange("/members")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Members
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/groups" ? true : false}
                        button
                        onClick={() => handleRouteChange("/groups")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Groups
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/charts" ? true : false}
                        button
                        onClick={() => handleRouteChange("/charts")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Charts
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/evaluations" ? true : false}
                        button
                        onClick={() => handleRouteChange("/evaluations")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Evaluations
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>,
                  document.querySelector("#root")
                )} */}
            </Grid>
            {/* <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <Avatar alt="Remy Sharp" src={profile && profile.photoUrl} className={classes.large} />
            </IconButton> */}
          </Grid>
        </Toolbar>
          {logoutStatusModal ? (
          <DialogNotification
            // hideDividers
            dialogActions={[
              { text: "Cancel", color:"#005597", onClick: () => handleDialogWindowClose() },
              { text:"Logout", color:"#fff", background: "#005597", onClick: () => onDialogActionsOnClick(), proceedAction: true, disabled: props.isLoading },
            ]}
            subRendercomponent={onRenderComponent()}
            title="Logout?"
            dialogRootProps={{ open: logoutStatusModal, onClose: () => setLogoutStatusModal(false) }}
          />
        ) : null}
      </AppBar>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    route: state.global.route,
    listUser: state.AuthReducer.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (data) => dispatch(setRoute(data)),
    logout: () => dispatch(logout()),
    getUser: (data) => dispatch(getUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
