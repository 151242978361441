import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, path, ...rest }) => {
  const render = (props) => <Component {...props} {...rest} />;

  return rest && rest.isAuthenticated ? <Redirect to="/tikkit" /> : <Route path={path} render={render} {...rest} />;
};

export default PublicRoute;
