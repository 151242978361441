import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { setRoute } from "../../../store/actions/Global";
import { SIDEBAR_MENU } from "../../../Constants/variables";
import { AGENT_SIDEBAR } from "../../../Constants/variables";
import Colors from "../../../Constants/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { auth_user } from "../../../Config/util";
import SideBarImage from "../../../Assets/Images/sidebar_bg.png";
import {  ReactComponent as TikkitLogo } from '../../../Assets/Images/logo.svg';

let DRAWER_WIDTH = 320;
let SIDEBAR;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    zIndex: 9,
    padding: 2,
    
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    zIndex: 9,
    border: "none",
    background:'#F2F5F7',
    backgroundImage: "url(" + SideBarImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    borderRight: "1px solid #9EBCD3",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  selected: {
    backgroundColor: `${Colors.secondary} !important`,
    borderRadius: 10,
    color: "#005597",
  },
  listRoot: {
    paddingLeft: 8,
    margin: 20,
    "&:hover": {
      borderRadius: 8,
    },
    // backgroundColor:'#DFEBF8'
  },
  buttonHover: {
    borderRadius: 8,
    "&:hover": {
      borderRadius: 8,
      backgroundColor:'#DFEBF8'
    },
  },
  listItemRoot: {
    minWidth: 40,
  },
}));

const SideBar = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:767px)");
  const { open, setOpen } = props;
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;
  const userType = auth_user()?.userType || props.user?.userType;
  if (userType === "Agent") {
    SIDEBAR = AGENT_SIDEBAR;
  }else{
    SIDEBAR = SIDEBAR_MENU;
  }
  const handleRouteChange = (route) => {
    props.setRoute(route);
    history.push(route.path);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={matches ? "temporary" : "permanent"}
        classes={{
          paper: classes.drawerPaper,
        }}
        open={matches ? open : false}
        onClose={() => setOpen(false)}
        anchor="left"
      >
        <div className={classes.toolbar} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: matches ? 15 : 0 }}>
          <TikkitLogo />
          <IconButton style={{ margin: "0px 5px" }} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List classes={{ root: classes.listRoot }}>
          {SIDEBAR.map(({ icon: Icon, ...route }) => (
            
            <ListItem
              style={{ alignItems: "center", marginTop: 10, fontFamily: "Poppins" }}
              classes={{ selected: classes.selected, button: classes.buttonHover }}
              selected={pathname.startsWith(route.path) ? true : false}
              button
              key={route.path}
              onClick={() => handleRouteChange(route)}
            >
              {Icon && (
                <ListItemIcon classes={{ root: classes.listItemRoot }}>
                  <Icon style={{ fill: pathname.startsWith(route.path) ? Colors.white : Colors.secondary, width: 32, height: 25 }} />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography variant={pathname.startsWith(route.path) ? "body1" : "subtitle2"} style={{ fontSize: 17, fontFamily: "Poppins", color: "#6C738B", fontWeight: 500 }}>
                    {route.title}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  const routePath = state.global.route;
  return { routePath };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (path) => dispatch(setRoute(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
