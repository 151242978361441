import { Support } from "../../actionTypes";

const initialState = {
  supportList: {},
};

const SupportReducer = (state = initialState, action) => {
    switch (action.type) {
        case Support.SUPPORT_LIST:  
            const isData = action.data ? { supportList: { ...action.data } } : null;
            return {
                ...state,
                ...isData,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};
export default SupportReducer;
