import { global } from "../../actionTypes";

const initialState = {
  route: {
    path: "/",
    title: "Dashboard",
  },
  snackBarProps: {
    open: false,
    variant: "success",
    duration: 6000,
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
  },
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case global.SET_ROUTE:
      return {
        ...state,
        route: action.data,
      };
    case global.SHOW_SNACKBAR:
      return {
        ...state,
        snackBarProps: { ...state.snackBarProps, ...action.data },
      };
    default:
      return state;
  }
};

export default CustomerReducer;
