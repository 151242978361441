import React from "react";

import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import global from "../../store/reducers/Global";
import AuthReducer from "../../store/reducers/Auth";
import TikkitReducer from "../../store/reducers/Tikkit";
import MemberReducer from "../../store/reducers/Member";
import ForgotPasswordReducer from "../../store/reducers/ForgotPassword";
import SupportReducer from "../../store/reducers/Support";
import PaymentReducer from "../../store/reducers/Payment";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  global,
  AuthReducer,
  TikkitReducer,
  MemberReducer,
  ForgotPasswordReducer,
  SupportReducer,
  PaymentReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const ProvideStore = (props) => {
  return <Provider store={store}>{props.children}</Provider>;
};

export default ProvideStore;
