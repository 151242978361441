import { Forgotpassword } from "../../actionTypes";

const initialState = {
  user: {},
  acceptPolicy: {},
};

const ForgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case Forgotpassword.FORGOTPASSWORD_SUCCESS:
        return {
            ...state,
            user: { success: true, ...action.forgotPassword },
        };
        case Forgotpassword.CHANGE_PASSWORD:
            const isData = action.data ? { userData: { ...action.data } } : null;
            return {
                ...state,
                ...isData,
                isLoading: action.isLoading,
              };
        case Forgotpassword.FORGOTPASSWORD_FAILURE:
        return {
            ...state,
            user: { success: false, ...action.error },
        };
        default:
        return state;
    }
};
export default ForgotPasswordReducer;
