import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid, useMediaQuery } from "@material-ui/core";
import Colors from "../../../Constants/colors";
// import SvgCloseRounded from "../../../../Assets/SVG/CloseRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
// import Slide from "@material-ui/core/Slide";
// (dialogRootProps) is to pass options to Main Dialog example like (open),
// (disableActions) is to disable Action section of dialog
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
  },
  root: {
    padding: "0px !important",
    border:"none",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    position: "relative",
    padding: "8px 16px",
    borderBottom: "none",
    backgroundColor: Colors.light_grey,
    border:"none",
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 8,
    color: theme.palette.grey[500],
    padding: 6,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
          <HighlightOffIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: "16px",
    fontFamily: "Poppins",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogNotification(props) {
  const classes = useStyles();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { title, dialogRootProps, dialogActions, disableActions, subRendercomponent, hideDividers, noWidth, customWidth } = props;

  return (
    <Dialog 
        {...dialogRootProps} fullWidth={!noWidth}  
        fullScreen={downSm ? true : false}  
        /* PaperProps={{ className: classes.paper }} > */
        PaperProps={{ style: {
          borderRadius: 16,
          maxWidth: (customWidth != undefined)? customWidth: "600px"
        } }} >
      {/* {props.CustomDialogTitle ? (
        props.CustomDialogTitle
      ) : (
        <DialogTitle onClose={dialogRootProps.onClose}>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item>
              <Typography variant="h6">{title.charAt(0).toUpperCase() + title.slice(1)}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      )} */}
      <DialogContent dividers={!hideDividers} className={classes.root}>
        {subRendercomponent}
      </DialogContent>
      {!disableActions ? (
        <DialogActions>
          {dialogActions && dialogActions.length
            ? dialogActions.map((button, keyConstant = Math.floor(Math.random() * 10)) => {
                return (
                  <Button key={keyConstant} 
                    style={{border: "0.5px solid #005597", fontFamily: "Poppins", color:button.color? button.color : "#000", background:button.background? button.background : "#FFF", borderRadius: "10px" }}
                    disabled={button.disabled} 
                    variant={button.proceedAction ? "contained" : "outlined"} 
                    onClick={button.onClick} >
                      {button.text}
                  </Button>
                );
              })
            : null}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

DialogNotification.defaultProps = {
  dialogRootProps: {
    open: false,
    onClose: () => {},
  },
  title: "DialogTitle",
  dialogActions: [
    { text: "Cancel", onClick: () => {} },
    { text: "Save", proceedAction: true, onClick: () => {} },
  ],
  disableActions: false,
};
