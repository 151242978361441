import { Tikkit } from "../../actionTypes";

const initialState = {
  tikkitList: {},
};

const TikkitReducer = (state = initialState, action) => {
  switch (action.type) {
    //CHART DASHBOARD
    case Tikkit.TIKKIT_LIST:  
      const isData = action.data ? { tikkitList: { ...action.data } } : null;
      return {
        ...state,
        ...isData,
        isLoading: action.isLoading,
      };
      case Tikkit.TIKKIT_UPDATE_STATUS:
        // let updateStatus = null;
        // const updatedData = action.data ? state.tikkitList?.data?.filter((x) => x._id !== action.data?._id) : null;
        // updateStatus = updatedData ? { tikkitList: { ...state.tikkitList, data: updatedData } } : null;
        return {
          ...state,
          ...action.data,
          isLoading: action.isLoading,
        };
      case Tikkit.TIKKIT_DETAILS:
        const tikkitDetails = action.data ? { tikkitDetails: { ...action.data } } : null;
        return {
          ...state,
          ...tikkitDetails,
          isLoading: action.isLoading,
        };
    default:
      return state;
  }
};
export default TikkitReducer;
