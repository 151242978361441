import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const CenterCircular = ({ disablePaper, size, disableMinHeight }) => (
  <Grid
    component={disablePaper ? "div" : Paper}
    elevation={1}
    style={{ minHeight: disableMinHeight ? disableMinHeight || 0 : "100vh" }}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    spaciong={3}
  >
    <Grid item>
      <CircularProgress size={size ? size : 50} />
    </Grid>
  </Grid>
);

export default CenterCircular;
