import React from "react";

import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

import { setSnackBarNotifications } from "../../../store/actions/Global";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function SnackbarNotification(props) {
  const classes = useStyles();
  const handleSnackBarClose = () =>
    props.dispatch(
      setSnackBarNotifications({
        open: false,
        variant: props.variant,

        // anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      })
    );
  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{ ...props.anchorOrigin }} open={props.open} autoHideDuration={props.duration} onClose={() => handleSnackBarClose()}>
        <Alert onClose={() => handleSnackBarClose()} severity={props.variant}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default connect()(SnackbarNotification);
