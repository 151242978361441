export const SIDEBAR_MENU = [
  { title: "Tickets", path: "/tikkit" },
  { title: "Attorneys", path: "/members/Agent" },
  { title: "Clients", path: "/members/Customer" },
  { title: "Transactions", path: "/payment" },
  { title: "Support", path: "/support" },
];
export const AGENT_SIDEBAR = [
  { title: "Tickets", path: "/tikkit" },
  { title: "Clients", path: "/members/Customer" },
];